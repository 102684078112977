import { Environment } from '@abp/ng.core';

const baseUrl = 'MY_APP_CONFIG';
export const environment = {
  production: true,
  sentry: {
    dsn: 'MY_SENTRY_DNS',
    environment: 'MY_SENTRY_ENVIRONMENT',
    release: 'MY_SENTRY_RELEASE',
  },
  application: {
    baseUrl,
    name: 'CreatorDonate',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'MY_OPENID_CONFIG' + '/',
    redirectUri: baseUrl,
    clientId: 'CreatorDonate_App',
    responseType: 'code',
    scope: 'offline_access CreatorDonate',
    requireHttps: true,
  },
  apis: {
    default: {
      url: 'MY_SWAGGER_CONFIG',
      rootNamespace: 'CreatorDonate',
    },
  },
  fileserverUrl: 'MY_FILE_SERVER_URL',
  square: {
    production: 'MY_SQUARE_PRODUCTION',
    applicationID: 'MY_SQUARE_APP_ID_CONFIG',
    locationID: 'MY_SQUARE_LOCATION_ID_CONFIG',
  },

  komoju: {
    publicKey: 'MY_KOMOJU_PUBLIC_KEY_CONFIG',
  },
  captcha: {
    // thay đổi khi build lên product
    siteKey: 'MY_CAPTCHA_SITE_KEY',
  },
  bucketname: 'MY_BUCKET_NAME',
} as Environment;
