import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { NotifyRessetPassFailComponent } from './dialog-notify/notify-resset-pass-fail/notify-resset-pass-fail.component';
import { HomeComponent } from './home/home.component';
import { VerifyEmailConfirmComponent } from './dialog-notify/verify-email-confirm/verify-email-confirm.component';
import { LoginStreamlabsSuccessComponent } from './dialog-notify/login-streamlabs-success/login-streamlabs-success.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RecoverEmailComponent } from './dialog-notify/recover-email/recover-email.component';

const routes: Routes = [
  {
    path: 'verify-email/:id/:code',
    component: VerifyEmailConfirmComponent,
    data: { returnUrl: window.location.pathname },
    title: 'Gipt | メール検証',
  },
  {
    path: 'auth/recover-email',
    data: { returnUrl: window.location.pathname },
    component: RecoverEmailComponent,
    title: 'Gipt | メールを回復する',
  },
  {
    path: 'resset-pass-fail',
    component: NotifyRessetPassFailComponent,
    data: { returnUrl: window.location.pathname },
    title: 'Gipt | パスワードを再設定する',
  },
  {
    path: 'login-streamlabs-success',
    component: LoginStreamlabsSuccessComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'main',
    loadChildren: () => import('../app/main/main.module').then(m => m.MainModule),
  },
  {
    path: 'home',
    component: HomeComponent,
    title: 'Gipt | クリエイターのためのギフトプラットフォーム',
  },
  {
    path: '',
    loadChildren: () => import('../app/page/page.module').then(m => m.PageModule),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
  {
    path: 'error/404',
    component: NotFoundComponent,
    title: 'Gipt | 404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
