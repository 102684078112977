<div class="row">
    <p-dialog position="top" [closable]="false" [(visible)]="visibleSuccess" [breakpoints]="{ '960px': '358px' }"
        [style]="{ width: '526px' , heigth:'240px'}" [draggable]="false" [modal]="true" [resizable]="false"
        styleClass="custom-dialog">
        <div>
            <img src="assets/images/icon/svg/warning.svg" alt="">
            <h3>
                {{'::RecoverMail:Success:Title'|abpLocalization}}
            </h3>
            <p>
                {{'::RecoverMail:Success:Content1'|abpLocalization}}
            </p>
            <p>
                {{'::RecoverMail:Success:Content2'|abpLocalization}}
            </p>
            <br>
            <a href="javascript:void(0)" (click) = "changePassword()">
                {{'::RecoverMail:Success:ChangePassword'|abpLocalization}}</a>
        </div>
    </p-dialog>
</div>

<div class="row">
    <p-dialog position="top" [closable]="false" [(visible)]="visibleFailed" [breakpoints]="{ '960px': '358px' }"
        [style]="{ width: '526px' , heigth:'240px'}" [draggable]="false" [modal]="true" [resizable]="false"
        styleClass="custom-dialog">
        <div>
            <img src="assets/images/icon/svg/warning.svg" alt="">
            <h3>
                {{'::VerifyEmail:ErrorMessage1'|abpLocalization}}
            </h3>
            <p>
                {{'::VerifyEmail:ErrorMessage2'|abpLocalization}}
            </p>
        </div>
    </p-dialog>
</div>
